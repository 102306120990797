/* Auth.scss */

$primary-color: #3498db;
$secondary-color: #333;
$background-color: #f4f4f4;
$border-color: #ddd;

  .buttonauth {
    margin: 10%;
    text-align: center;
    background-image: linear-gradient(to right, #25aae1, #4481eb, #04befe, #3f86ed);
    box-shadow: 0 4px 15px 0 rgba(65, 132, 234, 0.75);
    border: none;
    color: white;
    padding: 0.8em 1.2em;
    font-size: 1em;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .buttonauth:hover {
    background-color: darken(#25aae1, 10%);
  }

  h2 {
    font-size: 2em;
    margin-bottom: 1em;
    color: $secondary-color;
  }

  .button-container {
    display: flex;
    justify-content: space-between; /* Рівномірний розподіл між кнопками */
    width: 50%; /* Або використайте власну ширину, якщо потрібно */
    margin: 0 auto; /* Центрування контейнера */
  }

  .buttonauth {
    margin: 1%;
    text-align: center;
    background-image: linear-gradient(to right, #29323c, #485563, #2b5876, #4e4376);
    box-shadow: 0 4px 15px 0 rgba(45, 54, 65, 0.75);
    border: none;
    color: white;
    padding: 0.8em 1.2em;
    font-size: 1em;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;

    &:hover {
      background-color: darken(#29323c, 10%);
    }
  }
