/* Auth.css */

body{
  background: rgba(207,209,253,0.2);
}
.button-container {
  display: flex;
  justify-content: space-between; /* Рівномірний розподіл між кнопками */
  width: 50%; /* Або використайте власну ширину, якщо потрібно */
  margin: 0 auto; /* Центрування контейнера */
}

img{
  box-shadow: 0px 0px 255px 0 rgba(158, 158, 158, 0.75);
}
.piling{
  text-align: center;
}
h2{
  text-align: center;
}

.buttonauth {
  text-align: center;
  background-image: linear-gradient(to right, #29323c, #485563, #2b5876, #4e4376);
  box-shadow: 0 4px 15px 0 rgba(45, 54, 65, 0.75);
  border: none;
  color: white;
  padding: 0.8em 1.2em;
  font-size: 1em;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.buttonauth:hover {
  background-color: darken(#25aae1, 10%);
}
.button-container {
  display: flex;
  justify-content: space-between; /* Рівномірний розподіл між кнопками */
  width: 50%; /* Або використайте власну ширину, якщо потрібно */
  margin: 0 auto; /* Центрування контейнера */
}
.buttonauth {
  margin: 1%;
  position: relative;
  text-align: center;
  background-image: linear-gradient(to right, #29323c, #485563, #2b5876, #4e4376);
  box-shadow: 0 4px 15px 0 rgba(45, 54, 65, 0.75);
  border: none;
  color: white;
  padding: 0.8em 1.2em;
  font-size: 1em;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}
.buttonauth:hover {
  background-color: darken(#29323c, 10%);
}
