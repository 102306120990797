/* Ваш стиль для центрирования модального окна и затемненного фона */
.modal-content {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #fff; /* Цвет фона модального окна */
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 8px;
    box-shadow: 0 3px 5px rgba(0, 0, 0, 0.1);
    padding: 20px;
  }
  
  .modal {
    display: flex !important;
    align-items: center;
    justify-content: center;
  }
  
  .modal-backdrop.show {
    opacity: 0.5; /* Прозрачность фона (0-1) */
  }
  
  