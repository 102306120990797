/* NavList.css */

.nav-list {
    list-style-type: none; /* Убираем маркеры у тега li */
    padding: 0; /* Убираем внутренний отступ у списка */
  }
  
   li {
    display: inline-block; /* Делаем элементы списка блочными элементами в одной строке */
    margin-right: 15px; /* Добавляем небольшой отступ между элементами списка */
  }
  
   a {
    text-decoration: none; /* Убираем подчеркивание для ссылок */
    color: black; /* Цвет текста */
  }
  